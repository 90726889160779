import { FC } from "react";
import { description, navigation } from "../../data";
import { Background } from "../background";
import { Logo } from "../logo";
import { Nav } from "../nav";
import "./app.scss";

export const App: FC = () => {
  return (
    <>
      <div className="app">
        <Background />
        <section>
          <h1>Ken Mallar</h1>
          <h3>{description}</h3>
          <Logo />
          <Nav navigation={navigation} />
        </section>
        <Background />
      </div>
    </>
  );
};
