import { FC } from "react";

import "./logo.scss";

export const Logo: FC = () => {
  return (
    <div className="logo">
      <img src={require("../../assets/face_c.png")} alt="la face à mallar" />
    </div>
  );
};
